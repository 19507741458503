import { Button } from '@repo/ui/components/Button.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import * as React from 'react'
import { useTableContext } from './useTableContext'

export const DataTablePagination = ({
  className,
  ...props
}: React.ComponentProps<'nav'>) => {
  const {
    params,
    data: { pageCount },
    control
  } = useTableContext()

  if (!pageCount || pageCount <= 1) return null

  const hasNextPage = params.page < pageCount
  const hasPreviousPage = params.page > 1
  const nextPage = hasNextPage ? params.page + 1 : undefined
  const previousPage = hasPreviousPage ? params.page - 1 : undefined

  return (
    <Pagination {...props}>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            disabled={!hasPreviousPage}
            onClick={() => control.goToPage(params.page - 1)}
          />
        </PaginationItem>

        {params.page > 2 ? (
          <>
            <PaginationItem>
              <PaginationButton onClick={() => control.goToPage(1)}>
                1
              </PaginationButton>
            </PaginationItem>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          </>
        ) : null}

        {previousPage ? (
          <PaginationItem>
            <PaginationButton onClick={() => control.goToPage(previousPage)}>
              {previousPage}
            </PaginationButton>
          </PaginationItem>
        ) : null}

        <PaginationItem>
          <PaginationButton disabled isActive>
            {params.page}
          </PaginationButton>
        </PaginationItem>

        {nextPage ? (
          <PaginationItem>
            <PaginationButton onClick={() => control.goToPage(nextPage)}>
              {nextPage}
            </PaginationButton>
          </PaginationItem>
        ) : null}

        {Math.abs(pageCount - params.page) > 1 ? (
          <>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
            <PaginationItem>
              <PaginationButton onClick={() => control.goToPage(pageCount)}>
                {pageCount}
              </PaginationButton>
            </PaginationItem>
          </>
        ) : null}

        <PaginationItem>
          <PaginationNext
            disabled={!hasNextPage}
            onClick={() => control.goToPage(params.page + 1)}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & React.ComponentProps<typeof Button>

const PaginationButton = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) => (
  <Button
    aria-current={isActive ? 'page' : undefined}
    variant={isActive ? 'default' : 'ghost'}
    size={size}
    className={cn('w-8 h-7', isActive && 'disabled:opacity-100', className)}
    {...props}
  />
)
PaginationButton.displayName = 'PaginationButton'

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationButton>) => (
  <PaginationButton
    aria-label="Go to previous page"
    size="sm"
    className={cn('gap-1 pl-2.5 w-auto h-8', className)}
    {...props}
  >
    <Icon name="chevron-left" className="size-3" />
    <span>Prev</span>
  </PaginationButton>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationButton>) => (
  <PaginationButton
    aria-label="Go to next page"
    size="sm"
    className={cn('gap-1 pr-2.5 w-auto h-8', className)}
    {...props}
  >
    <span>Next</span>
    <Icon name="chevron-right" className="size-3" />
  </PaginationButton>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn(
      'flex h-9 w-9 items-center justify-center text-muted-foreground',
      className
    )}
    {...props}
  >
    <Icon name="dots-horizontal" className="size-3" />
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'
